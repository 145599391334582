const initialState = {
  demoModalActive: false,
  contactModalActive: false,
  demoformvalid : true,
  demoformsent : false,
  demoformsubmit: true,
  demowebhook : null,
  contactformvalid: true,
  contactformsent: false,
  contactformsubmit: true,
  form : {
    firstname : '',
    lastname : '',
    email : '',
    phone : '',
    organisation : '',
    jobtitle : '',
    dateselected: '',
    comment: 'Please contact me about your Aged Care Governance Assurance Program, I would like to know more.',
  },
  formtypes : {
    firstname : "text",
    lastname : "text",
    email : "email",
    phone : "phone",
    organisation : "text",
    jobtitle : "text",
    dateselected: 'text',
  },
  formerrors : {
    firstname : false,
    lastname : false,
    email : false,
    phone : false,
    organisation : false,
    jobtitle : false,
    dateselected: false,
    comment: false,
  },
  referral : {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    utm_content: null
  }
};

const CONTACT_FORM_WEBHOOK_URL = 'https://hooks.zapier.com/hooks/catch/8711204/ox8zmi7/';

const TOGGLE_DEMO_MODAL = 'TOGGLE_DEMO_MODAL';
const TOGGLE_CONTACT_MODAL = 'TOGGLE_CONTACT_MODAL';
const TOGGLE_MEETING_SUBMIT = 'TOGGLE_MEETING_SUBMIT';
const TOGGLE_CONTACT_SUBMIT = 'TOGGLE_CONTACT_SUBMIT';
const TOGGLE_BOTH_MODALS = 'TOGGLE_BOTH_MODALS';
const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
const UPDATE_MEETING_DATE = 'UPDATE_MEETING_DATE';
const SUBMIT_MEETING_FORM = 'SUBMIT_MEETING_FORM';
const SUBMIT_CONTACT_FORM = 'SUBMIT_CONTACT_FORM';
const RESET_MEETING_FORM = 'RESET_MEETING_FORM';
const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM';
const UPDATE_TRACKING_DATA = 'UPDATE_TRACKING_DATA';

export const toggleDemoModal = toggleDemoModal => ({ type: TOGGLE_DEMO_MODAL, toggleDemoModal });
export const toggleContactModal = toggleContactModal => ({ type: TOGGLE_CONTACT_MODAL, toggleContactModal });
export const toggleMeetingSubmit = toggleMeetingSubmit => ({ type: TOGGLE_MEETING_SUBMIT, toggleMeetingSubmit });
export const toggleContactSubmit = toggleContactSubmit => ({ type: TOGGLE_CONTACT_SUBMIT, toggleContactSubmit });
export const toggleBothModals = toggleBothModals => ({ type: TOGGLE_BOTH_MODALS, toggleBothModals });
export const updateFormField = updateFormField => ({ type: UPDATE_FORM_FIELD, updateFormField });
export const updateMeetingDate = updateMeetingDate => ({ type: UPDATE_MEETING_DATE, updateMeetingDate });
export const submitMeetingForm = submitMeetingForm => ({ type: SUBMIT_MEETING_FORM, submitMeetingForm });
export const submitContactForm = submitContactForm => ({ type: SUBMIT_CONTACT_FORM, submitContactForm });
export const resetMeetingForm = submitMeetingDate => ({ type: RESET_MEETING_FORM, submitMeetingDate });
export const resetContactForm = resetContactForm => ({ type: RESET_CONTACT_FORM, resetContactForm });
export const updateTrackingData = updateTrackingData => ({ type: UPDATE_TRACKING_DATA, updateTrackingData });

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DEMO_MODAL:
      return { ...state, demoModalActive: action.toggleDemoModal };
    case TOGGLE_CONTACT_MODAL:
      return { ...state, contactModalActive: action.toggleContactModal };
    case TOGGLE_MEETING_SUBMIT:
      return { ...state, demoformsubmit: action.toggleMeetingSubmit };
    case TOGGLE_CONTACT_SUBMIT:
      return { ...state, contactformsubmit: action.toggleContactSubmit };
    case TOGGLE_BOTH_MODALS:
      return { ...state, demoModalActive: action.toggleBothModals.demo, contactModalActive: action.toggleBothModals.contact, form: UpdateFormData(state.form , 'dateselected' , '')}
    case UPDATE_FORM_FIELD:
      return { ...state, form: UpdateFormData(state.form , action.updateFormField.name, action.updateFormField.value) };
    case UPDATE_MEETING_DATE:
      return { ...state, form: UpdateFormData(state.form , 'dateselected' , action.updateMeetingDate.mdate), demowebhook : action.updateMeetingDate.mhook };
    case SUBMIT_MEETING_FORM:
      let demoformsent = false;
      let formvalidation = ValidateForm(state.form, state.formtypes, state.formerrors);
      if(formvalidation.formvalid) {
        FormSubmit(state.form, state.demowebhook, state.referral);
        demoformsent = true;
      }
      return { ...state, formerrors: formvalidation.formerrors, demoformvalid: formvalidation.formvalid, demoformsubmit: true, demoformsent: demoformsent };
    case RESET_MEETING_FORM:
      return { ...state, form: UpdateFormData(state.form , 'dateselected' , ''), demowebhook : '', demoformsent : false };
    case SUBMIT_CONTACT_FORM:
      let contactformsent = false;
      // set a dummy calendar date - so we can use the same form details
      let tempform = {...state.form, dateselected: 0};
      let contactvalidation = ValidateForm(tempform, state.formtypes, state.formerrors);

      if(contactvalidation.formvalid) {
       FormSubmit(tempform, CONTACT_FORM_WEBHOOK_URL, state.referral);
        contactformsent = true;
      }
      return { ...state, formerrors: contactvalidation.formerrors, contactformvalid: contactvalidation.formvalid, contactformsubmit: true, contactformsent: contactformsent };
    case RESET_CONTACT_FORM:
      return { ...state, form: UpdateFormData(state.form , 'dateselected' , ''), contactformsent : false };
    case UPDATE_TRACKING_DATA:
      return {...state, referral : {
          utm_source: action.updateTrackingData.utm_source,
          utm_medium: action.updateTrackingData.utm_medium,
          utm_campaign: action.updateTrackingData.utm_campaign,
          utm_term: action.updateTrackingData.utm_term,
          utm_content: action.updateTrackingData.utm_content
        }};
    default:
      return state;
  }
};

function UpdateFormData(form, field, value) {
  return { ...form, [field]: value };
}

function ValidateForm(values, types, errors) {
  let formvalid = true;
  let formerrors =  Object.assign({}, errors );
  for (let [key, value] of Object.entries(values)) {
    let fieldstate = ValidateDataInError(value, types[key]);
    formerrors[key] = fieldstate;
    if(fieldstate)
      formvalid = false;
  }
  return Object.assign({}, { formvalid: formvalid, formerrors: formerrors } );
}

function ValidateDataInError(value, type) {
  if( type === 'text' ) {
    if(value === '')
      return true;
  }
  if( type === 'email' ) {
    if(!validateEmail(value))
      return true;
  }
  if( type === 'phone' ) {
    if(!validatePhoneNumber(value))
      return true;
  }
  return false
}

function validateEmail(email) {
  const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}

function validatePhoneNumber(number) {
  // original regex (but to many unnecessary escaped characters?) const re = /^(\+\d{2}[ \-]{0,1}){0,1}(((\({0,1}[ \-]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ \-]*(\d{4}[ \-]{0,1}\d{4}))|(1[ \-]{0,1}(300|800|900|902)[ \-]{0,1}((\d{6})|(\d{3}[ \-]{0,1}\d{3})))|(13[ \-]{0,1}([\d \-]{5})|((\({0,1}[ \-]{0,1})0{0,1}\){0,1}4{1}[\d \-]{8,10})))$/;
  const re = /^(\+\d{2}[ -]{0,1}){0,1}(((\({0,1}[ -]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ -]*(\d{4}[ -]{0,1}\d{4}))|(1[ -]{0,1}(300|800|900|902)[ -]{0,1}((\d{6})|(\d{3}[ -]{0,1}\d{3})))|(13[ -]{0,1}([\d -]{5})|((\({0,1}[ -]{0,1})0{0,1}\){0,1}4{1}[\d -]{8,10})))$/;
  return re.test(String(number));
}

function cleanPhoneNumber(number) {
  return number.replace(/\D/g, '');
}

function FormSubmit(form, formhandlerurl, referral) {
  var d = new Date(form.dateselected);
  var formattedDate = d.toISOString();

  let postdata = {firstname: form.firstname, lastname: form.lastname, email: form.email , phone: cleanPhoneNumber(form.phone) , organisation: form.organisation , jobtitle: form.jobtitle, meetingdate: formattedDate, comment: form.comment, utm_source: referral.utm_source, utm_medium: referral.utm_medium, utm_campaign: referral.utm_campaign, utm_term: referral.utm_term, utm_content: referral.utm_content };

  return fetch(formhandlerurl, {
    method: "POST",
    body: JSON.stringify(postdata),
  })
    .then(response => response.json())
    .then(json => console.log(json))
    .catch(err => console.log(err));
}
